@import '~bootstrap/scss/bootstrap.scss';

// $mdi-font-path: '~mdi/fonts/';
// @import '~mdi/scss/materialdesignicons';
@import '@mdi/font/scss/materialdesignicons.scss';

@font-face {
  font-family: 'Varela';
  src: url('./assets/fonts/VarelaRound-Regular-webfont.eot');
  src: url('./assets/fonts/VarelaRound-Regular-webfont.eot');
  src: url('./assets/fonts/VarelaRound-Regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/VarelaRound-Regular-webfont.woff') format('woff'),
       url('./assets/fonts/VarelaRound-Regular-webfont.ttf') format('truetype'),
       url('./assets/fonts/VarelaRound-Regular-webfont.svg#VarelaRound-Regular-webfont') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-Light-webfont.eot');
  src: url('./assets/fonts/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/OpenSans-Light-webfont.woff') format('woff'),
       url('./assets/fonts/OpenSans-Light-webfont.ttf') format('truetype'),
       url('./assets/fonts/OpenSans-Light-webfont.svg#open_sanslight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-LightItalic-webfont.eot');
  src: url('./assets/fonts/OpenSans-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/OpenSans-LightItalic-webfont.woff') format('woff'),
       url('./assets/fonts/OpenSans-LightItalic-webfont.ttf') format('truetype'),
       url('./assets/fonts/OpenSans-LightItalic-webfont.svg#open_sanslight_italic') format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-Bold-webfont.eot');
  src: url('./assets/fonts/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/OpenSans-Bold-webfont.woff') format('woff'),
       url('./assets/fonts/OpenSans-Bold-webfont.ttf') format('truetype'),
       url('./assets/fonts/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-BoldItalic-webfont.eot');
  src: url('./assets/fonts/OpenSans-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/OpenSans-BoldItalic-webfont.woff') format('woff'),
       url('./assets/fonts/OpenSans-BoldItalic-webfont.ttf') format('truetype'),
       url('./assets/fonts/OpenSans-BoldItalic-webfont.svg#open_sansbold_italic') format('svg');
  font-weight: bold;
  font-style: italic;
}

body {
  font-family: 'Varela', Arial, sans-serif !important;
}

.text-white {
  color: #ffffff;
}