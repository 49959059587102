.language-selector-container {
  span {
    &:first-child {
      &:after {
        content: '|';
        color: white;
        margin-left: 5px;
        margin-right: 5px;
      }
    }

    &.selected {
      color: #62D2F0;
      cursor: initial;
    }

    &:not(.selected) {
      cursor: pointer;
    }
  }
}


.home {
  header {
    padding-top: 10px;
    padding-bottom: 0.5rem;

    .MuiToolbar-root {
      max-width: 1140px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      width: 100%;

      .for-desktop {
        margin-left: auto;

        .language-selector-container {
          margin-left: 10px;
        }
        button, .language-selector-container {
          color: white;

          &.btn-outline-primary {
            border-radius: 300px;
            color:  #62D2F0;
            background-color: transparent;
            background-image: none;
            border: 2px solid  #62D2F0;
            padding: .3rem 1.75rem;

            &:hover {
              color: #fff;
              background-color: #62D2F0;
              border-color: #62D2F0;
            }
          }
          
        }
      }
    }
    .header-logo-default {
      display: block;
    }

    .header-logo-scrolled {
      display: none;
    }

    &.scrolled {
      .MuiToolbar-root {
        .for-desktop {
          > button {
            outline: none;
          }

          button:not(.btn-outline-primary) {
            color: initial;
          }

          .language-selector-container {
            color: initial;

            span {
              &:first-child {
                &:after {
                  color: initial;
                }
              }
            }
          }
        }

        .for-mobile {
          > button.MuiIconButton-root {
            outline: none;
            color: black;
          }
        }
      }
      .header-logo-default {
        display: none;
      }
  
      .header-logo-scrolled {
        display: block;
      }
    }
  }
  .h2, h2 {
    font-size: 2.5rem;
    line-height: 2.5rem;
    letter-spacing: 0.1rem;
    font-family: 'Varela', Arial, sans-serif;
    font-weight: 100;
    text-shadow: 2px 2px 8px #000000;
  }

  .section-container {
    display: flex;
    padding-top: 10rem;
    padding-bottom: calc(10rem - 56px);
    background-position: center center;
    background-size: cover;
    position: relative;
    height: 100vh;
    min-height: 650px;
    background-color: white;

    &.backdrop:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0, 0.4);
      z-index: 0;
    }
  }

  .lets-start-section {
    

    .container {
      z-index: 1;
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
      max-width: 1140px;
      

      // .lets-started {
      //   background-color: rgba(28,133,161, 0.6);
      //   border: 1px solid #ffffff;
      //   color: #fff;
      // }
    }
  }

  .reporting {
    padding-top: 0;
    padding-bottom: 0;
    .container {
      display: flex;
      .section-title {
        font-size: 2.4rem;
      }

      .section-legend {
        font-size: 1.1rem;
        font-family: 'Varela', Arial, sans-serif;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
      }

      .text-muted {
        color: #6c757d!important;
      }
    }
  }

  .easy-to-use {
    h3 {
      font-size: 2.4rem;
      font-weight: 100;
      font-family: 'Varela', Arial, sans-serif;
      line-height: 2.4rem;
    }

    h4 {
      font-size: 1.1rem;
      font-family: 'Varela', Arial, sans-serif;
      letter-spacing: 0.3rem;
      text-transform: uppercase;
    }

    p {
      margin-top: 0;
      margin-bottom: 1rem;
    }

    .btn-icon {
      font-size: 4rem;
      cursor: pointer;
      color: #ffffff !important;
      transition: all ease 0.3s;
      text-align: center;
      padding: 0;
      display: inline-block;
      background-color: #62D2F0;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      line-height: 5rem;
      margin: 0.5rem 0 0.2rem 0;
    }
  }

  .our-benefits {
    h3 {
      font-size: 2.4rem;
    }
    .section-legend {
      font-size: 1.1rem;
      font-family: 'Varela', Arial, sans-serif;
      letter-spacing: 0.3rem;
      text-transform: uppercase;
    }

    .text-benefit {
      padding: 0 20px;

      h5 {
        font-size: 0.8rem;
        font-family: 'Varela', Arial, sans-serif;
        letter-spacing: 0.2rem;
        text-transform: uppercase;
      }
    }
    img {
      height: 150px;
    }
  }

  .contact-us {
    .container {
      .plan-selected {
        margin-bottom: 15px;
      }
      .contact-box {
        .mdi {
          font-size: 2rem;
        }
      }
    }
  }

  .pricing {
    height: auto;
    h3 {
      font-size: 2.4rem;
    }

    .section-legend {
      font-size: 1.1rem;
      font-family: 'Varela', Arial, sans-serif;
      letter-spacing: 0.3rem;
      text-transform: uppercase;
    }

    background-color: #f2f2f2;

    .container {
      .row {
        justify-content: center;

        .plans-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          
          // .plan-container ~ .plan-container {
          //   margin-left: 20px;
          // }

          .plan-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            //border: 1px solid #62D2F0;
            padding: 30px 15px;
            border-radius: 5px;
            margin-top: 30px;
            margin-left: 20px;
            min-width: 210px;
            background-color: white;

            .title {
              font-size: 1.1rem;
              margin-bottom: 20px;
            }

            .princing {
              display: flex;
              align-items: flex-end;
              margin-bottom: 20px;

              .amount-container {
                position: relative;
                display: inline-flex;
                line-height: 1;

                .amount {
                  font-size: 1.5rem;
                }
              }
            }

            .range-users {
              padding: 5px 10px;
              margin-bottom: 20px;
              border: 1px solid #f2f2f2;
              border-radius: 15px;
              background-color: #f2f2f2;
            }

            .plan-features {
              display: flex;
              flex-direction: column;
              align-items: center;

              div ~ div {
                margin-top: 5px;
              }
            }

            .start-plan-button-container {
              margin-top: auto;
              padding-top: 30px;

              button {
                color: #fff;
                background-color: #62D2F0;
                border-color: #62D2F0;
              }
            }
          }
        }
      }
    }
  }

  .customers {
    height: auto;
    // min-height: 100vh;

    .container {
      .image-containers {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 10px;

        > div {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 140px;
            height: auto;
            max-height: 300px;
          }
        }
      }
    }
  }

  .footer {
    .nav-footer {
      margin-top: 10px;

      .nav-item {
        border-right: 1px solid #5F5F5F;
  
        &:last-child {
          border-right: none;
        }
  
        .nav-link {
          color: #5F5F5F;
          font-size: 0.8rem;
          padding: 0rem 1rem;
          transition: all ease 0.3s;
        }
      }
    }

    .nav-social {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      flex-wrap: nowrap;

      .nav-item {
        .nav-link {
          color: #5F5F5F;
          font-size: 1.3rem;
        }
      }
    }
    
  }
}


.no-a-styles, .no-a-styles:hover {
  color: inherit;
  text-decoration: none;
  background-color: inherit;
}

.f-width {
  width: 100%;
}

.btn {
  border: none;
  font-size: 18px;
  letter-spacing: 0.08rem;
  border-radius: 300px;
  font-family: 'Varela', Arial, sans-serif;
  transition: all ease 0.3s;
  padding: 0.8rem 2.5rem;
  

  &.btn-primary {
    background-color: rgba(28,133,161, 0.6);
    border: 1px solid #ffffff;
    color: #fff;

    &:hover {
      background-color: #62D2F0;
      border: 1px solid #ffffff;
    }
  }
}

.center-do-not-use {
  display: block;
  margin: 0 auto;
  position: absolute;
  bottom: 80px;
  right: 20px;
  width: 70px;
  text-align: center;
  z-index: 1;

  .arrow {
    display: block;
    -ms-transform: rotate(45deg);  /* IE 9 */
    -webkit-transform: rotate(45deg);  /* Chrome, Safari, Opera */
    transform: rotate(45deg);
    border-right: 1px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
    margin: 5px auto 8px;
    width: 10px;
    height: 10px;
    -webkit-animation: mouse-scroll .5s infinite;
    -moz-animation: mouse-scroll .5s infinite;
    -webkit-animation-direction: alternate;
  }

  .mouse {
    height: 42px;
    width: 24px;
    border-radius: 14px;
    transform: none;
    border: 2px solid #FFFFFF;
    margin: auto;
  }

  .wheel {
    height: 5px;
    width: 2px;
    display: block;
    margin: 5px auto;
    background: #FFFFFF;
    position: relative;
    height: 4px;
    width: 4px;
    border: 2px solid #FFFFFF;
    border-radius: 8px;
  }

  .wheel {
    -webkit-animation: mouse-wheel 1s linear infinite;
    -moz-animation: mouse-wheel 1s linear infinite;
  }

  .btn-float-link {
    color: #ffff;
    border: 1px solid #ffffff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background-color: rgba(0,0,0, 0.5);
    transition: all ease 0.3s;
    font-size: 2rem;
    padding: 5px 10px;
    text-align: center;
    display: inline-block;

    &:hover {
      background-color: #62D2F0;
      color: #ffff;
    }
  }
}

@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/*END - MODAL */

@-webkit-keyframes mouse-wheel {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@-moz-keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 6px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}

@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  25% {
   opacity: .25;
  }
  50% {
    opacity: .5;
  }
  75% {
    opacity: .75;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  25% {
   opacity: .25;
  }
  50% {
    opacity: .5;
  }
  75% {
    opacity: .75;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  25% {
   opacity: .25;
  }
  50% {
    opacity: .5;
  }
  75% {
    opacity: .75;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 1200px) {
  .h2, h2{
    font-size: 2.5rem;
  }
}


@media (max-width: 991px) {
  .h2, h2 {
    font-size: 1.6rem;
    line-height: 1.7rem;
  }

  .home {
    .section-container {
      height: auto;
    }

    .reporting {
      padding-top: 80%;
      background-size: 130% auto;
      background-position: center top;
      background-repeat: no-repeat;
    }

    .center-do-not-use {
      &.float-arrows {
        display: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .home {
    .customers {
      .container {
        .image-containers {
          > div {
            img {
              width: 75px;
            }
          }
        }
      }      
    }

    .pricing {
      padding-top: 2rem;
    }
  }
}

.iframe-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
}
