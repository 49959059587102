.thanks-wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;

  .thanks {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: auto;
    padding: 20px 20px 20px;
    // width: 310px;
    height: fit-content;
    background: white;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 200px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 200px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.3);

    &:before {
      content: '';
      position: absolute;
      top: -8px;
      right: -8px;
      bottom: -8px;
      left: -8px;
      z-index: -1;
      background: rgba(0, 0, 0, 0.08);
      border-radius: 4px;
    }

    .back-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      span {
        padding: 10px;
        color: #62D2F0;
        cursor: pointer;
      }
    }
  }
}
